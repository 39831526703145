import React, { useEffect, useState } from "react";
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import About from './components/UI/About';
// import Blog from './components/UI/Blog';
// import Counter from './components/UI/Counter';
import Hero from './components/UI/Hero';
// import Newsletter from './components/UI/Newsletter';
import Contact from "./components/UI/Contact";
import Services from './components/UI/Services';
// import Teams from './components/UI/Teams';
// import Testimonial from './components/UI/Testimonial';

function App() {

 const [theme, setTheme] = useState('')

 const toggleTheme = () =>{
  theme === '' ? setTheme('light-theme') : setTheme('')
 }

 useEffect(() =>{
  document.body.className = theme
 }, [theme]);



  return (
   <>
      <Header  theme={theme} toggleTheme={toggleTheme}/>
      <Hero  theme={theme}/>
      {/* <Counter /> */}
      <Services />
      <About />
      {/* <Teams /> */}
      {/* <Blog /> */}
      {/* <Testimonial /> */}
      {/* <Newsletter /> */}
      <Contact theme={theme}/>
      <Footer />
   </>
  );
}

export default App;
