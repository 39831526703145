import React from 'react';
import '../../styles/about.css';

import aboutImg from '../../images/about-us.jpg';

const chooseData = [
    {
        icon:'ri-line-chart-fill',
        title:'Tailored Solutions',
        desc:"Customization is our hallmark. Each project is uniquely tailored to your requirements, ensuring solutions that perfectly align with your business objectives."
    },
    {
        icon:'ri-team-line',
        title:'Proven Expertise',
        desc:"Our team, seasoned industry experts, has served diverse clients, granting us the insight and experience to address your specific needs successfully."
    },
    {
        icon:'ri-auction-fill',
        title:'Commitment to Excellence',
        desc:"We embody excellence, driven by an unwavering commitment to deliver exceptional service through a relentless pursuit of perfection."
    },  
]

const About = () => {
  return (
   <section id="about">
      <div className="container">
        <div className="about__wrapper">
            <div className="about__content">
                  <h6 className='about-head'>Why choose us</h6>
                  <h2>Experience, Expertise, Excellence : Why <span className="highlight">Kreate App</span> Stands Out </h2>
                  {/* <h2 className="highlight">financial Challenges</h2> */}
                  <p className="description about__content-desc">We don't just promise results, we deliver them consistently. Here's why you should choose us as your trusted partner:</p>

                
                <div className='choose_-item-wrapper'>
                    {
                        chooseData.map((item,index) =>(
                            <div className="choose__us-item" key={index}>
                            <span className="choose__us-icon"><i className={item.icon}></i></span>
                            <div>
                                  <h4 className="choose__us-title">{item.title}</h4>
                                  <p className="description">{item.desc}</p>
                            </div>
                         </div>
                        ))
                    }
                </div>
            </div>

            <div className="about__img">
                <img src={aboutImg} alt="" />
            </div>
        </div>
      </div>
   </section>
  )
};

export default About
