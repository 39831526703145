import React from 'react'
import heroDarkImg from '../../images/hero-img.png'
import lightImg from '../../images/light-hero-bg.jpg'
import '../../styles/hero.css'


const Hero = ({theme}) => {
    const handleClick = e =>{
        e.preventDefault()

        const targetAttr = e.target.getAttribute('href')
        const location = document.querySelector(targetAttr).offsetTop;

        window.scrollTo({
            left:0,
            top:location - 80,
        });
    };
  return (
  <section className="hero__section" id="home">
    <div className="container">
        <div className="hero__wrapper">
            <div className="hero__content">
                <div>
                    <h2>Krafting Excellence:</h2>
                    <h2>We Build, Design and Ignite</h2>
                    <h2 className='highlight'>Your Brand</h2>
                </div>
                <p className="description">Experience a transformation with our cutting-edge solutions. We empower you with the essential tools to not only elevate your brand but also drive your success to new heights.</p>


                <div className="hero__btns">
                    <a href='#contact' onClick={handleClick} className="primary__btn">Get Started Now</a>
                    {/* <button className="secondary__btn">Discover More</button> */}
                </div>
            </div>

            <div className="hero__img">
                <img src={theme === "light-theme" ? lightImg :  heroDarkImg} alt="hero-img" />
            </div>
        </div>
    </div>
  </section>
  )
}

export default Hero
