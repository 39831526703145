import React from 'react';
import './footer.css';


// const quickLinks01 = [
//     {
//         path:'#',
//         display: "Marketing"
//     },
//     {
//         path:'#',
//         display: "Analytics"
//     },
//     {
//         path:'#',
//         display: "Commerce"
//     },
// ];



// const quickLinks02 = [
//     {
//         path:'#',
//         display: "Pricing"
//     },
//     {
//         path:'#',
//         display: "Documentation"
//     },
//     {
//         path:'#',
//         display: "Guides"
//     },
// ];

// const quickLinks03 = [
//     {
//         path:'#about',
//         display: "About"
//     },
//     {
//         path:'#',
//         display: "Job"
//     },
//     {
//         path:'#blog',
//         display: "Blog"
//     },
// ];
const Footer = () => {

   const year = new Date().getFullYear();
//    console.log(year, "YEAR");

  return (
    <footer className="footer">
        <div className="container">
            <div className="footer__wrapper">
                {/* <div className="footer__logo">
                    <span className='company-name'>
                    <h2>Kreate App</h2>
                    <p className="description">Grow with us</p>
                    </span>

                    <p className="small__text description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, provident ut harum quidem eligendi commodi inventore veniam hic. Ullam at reprehenderit ab enim accusantium non dolore quas sint consequuntur illum!</p>

                </div> */}

                {/* <div className="footer__quick-links">
                    <h3 className="quick__links-title">Solution</h3>
                    <ul className="quick__links">
                        {
                            quickLinks01.map((item,index) =>(
                                <li className="quick__link-item" key={index}>
                                    <a href={item.path}>{item.display}</a>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className="footer__quick-links">
                    <h3 className="quick__links-title">Support</h3>
                    <ul className="quick__links">
                        {
                            quickLinks02.map((item,index) =>(
                                <li className="quick__link-item" key={index}>
                                    <a href={item.path}>{item.display}</a>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className="footer__quick-links">
                    <h3 className="quick__links-title">Company</h3>
                    <ul className="quick__links">
                        {
                            quickLinks03.map((item,index) =>(
                                <li className="quick__link-item" key={index}>
                                    <a href={item.path}>{item.display}</a>
                                </li>
                            ))
                        }
                    </ul>
                </div> */}
            </div>

            <p className="copyright">© {year} Kreate-App. Your trusted partner for web development, design, SEO, and content creation.</p>
            <p className='copyright-1'>Let's shape your digital future together.</p>
            {/* <p className="copyright">Copyright {year}, Developed by Kreate App. All rights reserved</p> */}
        </div>
    </footer>

  )
}

export default Footer
