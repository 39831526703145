import React from "react";
import "../../styles/services.css";

const serviceData = [
  {
    icon: "ri-double-quotes-l",
    title: "Content Creation",
    desc: "Attract and educate your audience with premium content. Our skilled writers and creators produce compelling articles, blogs, and social media content that resonate and drive your content strategy!",
  },

  {
    icon: "ri-code-s-slash-line",
    title: "Web Development",
    desc: "Transform your digital presence with our expert web development solutions. We create responsive, user-friendly websites and custom web applications that bring your vision to life!",
  },

  {
    icon: "ri-quill-pen-fill",
    title: "Designing",
    desc: "Elevate your brand with captivating graphic design. Our talented designers craft visual identities that make a lasting impression, from eye-catching logos to stunning marketing materials!",
  },
  {
    icon: "ri-search-eye-line",
    title: "SEO",
    desc: "Elevate your brand with captivating content. Our expert writers create engaging articles, blogs, and social media posts that connect with your audience and boost your content strategy!",
  },
];

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-content">
          <h6 className="services-head">Our Services</h6>
          <div className="services-subhead">
            <h2>
              Empower Your Online Presence with{" "}
              <span className="highlight-services">Kreate-App</span>
            </h2>
          </div>
        </div>

        <div className="service__item-wrapper">
          {serviceData.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="services__icon">
                <i className={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
