import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBQM7ABwvgHWRP_Q_EtPx8o5l4P4JuizTM",
  authDomain: "contact-us-kreate-app.firebaseapp.com",
  projectId: "contact-us-kreate-app",
  storageBucket: "contact-us-kreate-app.appspot.com",
  messagingSenderId: "760599642310",
  appId: "1:760599642310:web:c26d1829b5d61fe22117c7",
  measurementId: "G-Y2H7Z28GN6"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
