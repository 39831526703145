import React, { useState } from "react";
import "../../styles/contact.css";
import { db } from "../../firebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import darkcontactimg from "../../images/dark-contact.png";
import lightcontactimg from "../../images/light-contact.png";

const Contact =({theme}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    };

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address";
      isValid = false;
    }

    if (formData.phoneNumber.trim() && !/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Enter a valid 10-digit phone number";
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }
    console.log(newErrors)
    setErrors(newErrors);
    return isValid;
  };

  const usercontactdata = collection(db, "contactdata");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // console.log("Form submitted:", formData);
      addDoc(usercontactdata, {
        firstname: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        message: formData.message,
      });
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  };

  return (
    <div className="contactus" id="contact">
      <div className="container-cont">
        <div className="contact-head">
          <div className="newsletter__wrapper">
            <div className="newsletter_content">
              <h2>
                Explore the <span className="highlight-services">hidden</span> ideas and
                Connect!
              </h2>
            </div>
            <div className="newsletter_content">
              <h6 className="subtitle">
                Reach us @{" "}
                <span className="newsletter-text">kreateapp@gmail.com</span>
              </h6>
            </div>
          </div>
          <div className="contactus-img">
            <img src={theme === "light-theme" ? lightcontactimg :  darkcontactimg} className="img-contact" />
          </div>
        </div>
        <div className="form-container">
          <form onSubmit={handleSubmit} className="form">
            <div className="name-container">
              <div className="fname">
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <div>
                  {errors.firstName && (
                    <div className="error-message ">{errors.firstName}</div>
                  )}
                </div>
              </div>
              <div className="lname">
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <div>
                  {errors.lastName && (
                    <div className="error-message ">{errors.lastName}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="email-container">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
            <div className="phone-container">
              <label htmlFor="phoneNumber">Phone Number (Optional):</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              {errors.phoneNumber && (
                <span className="error-message">{errors.phoneNumber}</span>
              )}
            </div>
            <div className="msg-container">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && (
                <span className="error-message">{errors.message}</span>
              )}
            </div>
            <div className="btn-submit">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
