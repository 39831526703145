import React, { useEffect, useRef } from 'react'
import logo from "../../images/logo.png"
import './header.css'



const nav__links = [
    {
        path: '#home',
        display:'Home'
    },
    {
        path: '#service',
        display:'Services'
    },
    {
        path: '#about',
        display:'About Us'
    },
    {
        path: '#contact',
        display:'Contact Us'
    },
    // {
    //     path: '#projects',
    //     display:'Projects'
    // },
    // {
    //     path: '#blog',
    //     display:'Blog'
    // },
]


const Header = ({theme, toggleTheme}) => {

    const headerRef = useRef(null)

    const menuRef = useRef(null)

    const headerFunc = () =>{
        if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
            headerRef.current.classList.add('header__shrink');
        }else{
            headerRef.current.classList.remove('header__shrink');
        }
    }

    useEffect(() => {
        window.addEventListener("scroll",headerFunc);

        return () => window.removeEventListener("scroll", headerFunc);
    },[]);


    const handleClick = e =>{
        e.preventDefault()

        const targetAttr = e.target.getAttribute('href')

        const location = document.querySelector(targetAttr).offsetTop;

        window.scrollTo({
            left:0,
            top:location - 80,
        });
    };


    const toggleMenu = () => menuRef.current.classList.toggle('menu__active')

  return (
    <div>
      <header className="header" ref={headerRef}>
        <div className="container-head">
            <div className="nav__wrapper">
                <div className="logo">
                    <img src={logo} className='logo-img'/>
                    <h2>Kreate App</h2>
                  
                </div>

                {/* ===========================  navigation =================================== */}
                <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                    <ul className="menu">
                         {
                            nav__links.map((item,index) => (
                                <li className="menu__item" key={index}>
                                    <a href={item.path} onClick={handleClick} className="menu__link">
                                        {item.display}
                                    </a>
                                </li>
                            ))
                         }
                    </ul>
                </div>

               {/* ===========================  light mode =================================== */}
              <div className="light__mode">
                <span onClick={toggleTheme}>
                    {
                        theme === 'light-theme' ?( 
                        <span>
                            <i className="ri-moon-line"></i>Dark
                        </span> ) :  ( 
                        <span> 
                             <i className="ri-sun-line"></i>Light
                        </span>)
                    }
                  
                    
                </span>
              </div>

              <span className="mobile__menu" onClick={toggleMenu}>
                 <i className="ri-menu-line"></i>
              </span>

            </div>
        </div>
      </header>
    </div>
  )
}

export default Header
